<template>
  <b-row>
    <b-col cols="12">
      <b-navbar toggleable="lg" type="light" variant="link" class="px-0 py-4 barra-nav">
        <b-navbar-toggle target="nav-collapse" class="btn-nav">
          <template #default><i class="las la-bars fs-35 info"></i></template>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <template v-for="menuOption in orderMenu(menuOptions)">
              <b-nav-item
                class="text-lg-center"
                :key="menuOption.id"
                @click="isExternal(menuOption)"
                v-if="menuOption.hijosMenu.length === 0"
                :to="menuOption.external === 'N' ? menuOption.url : null"
              >
                {{ menuOption.nombreMenu.toUpperCase() }}
              </b-nav-item>
              <b-nav-item-dropdown
                v-else
                right
                :key="menuOption.id"
                :text="menuOption.nombreMenu"
                :class="`text-lg-center ${menuOption.id == 11 ? 'btn-sm btn-perfil content-center p-0' : ''}`"
              >
                <template slot="button-content">
                  {{ menuOption.nombreMenu.toUpperCase() }}
                </template>
                <template v-for="subMenuOption in orderMenu(menuOption.hijosMenu)">
                  <b-dropdown-item :key="subMenuOption.id" :to="subMenuOption.url">
                    {{ subMenuOption.nombreMenu.toUpperCase() }}
                  </b-dropdown-item>
                </template>
              </b-nav-item-dropdown>
            </template>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-col>
    <b-col cols="12" class="content-end-center">
      <p class="mb-0 fs-12 ultimo-acceso fw-bold text-right"><i class="las la-star danger"></i> {{ ultimoIngreso }}</p>
    </b-col>
  </b-row>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return { menuOptions: [], ultimoIngreso: null };
  },
  created() {
    this.menuOptions = this.$store.getters.menuOptions;
    this.ultimoIngreso = `Último ingreso: ${this.$store.getters.ultimoIngreso} - IP: ${this.$store.getters.ip}`;
  },
  methods: {
    orderMenu(menuList) {
      const newMenu = menuList.map((elem) => elem);
      return newMenu.sort((elem1, elem2) => (elem1.ordenMenu > elem2.ordenMenu ? 1 : -1));
    },
    isExternal(menuOption) {
      if (menuOption.external === "S") {
        const body = { email: "externo@raas.com", password: "zUd8NP8PhFW2GwpD1SJg" };
        this.$store.commit("switchLoading", true);
        axios.post("https://raas.com.co/raasApi/security/auth/v1/signin", body).then((response) => {
          const config = { headers: { Authorization: `${response.data.tokenType} ${response.data.accessToken}` } };
          const body = {
            tipoDoc: "Cedula de ciudadania",
            direccion: this.$store.getters.direccion,
            nombres: this.$store.getters.nombreCliente,
            celular: this.$store.getters.numeroCelular,
            email: this.$store.getters.correoElectronico,
            identificacion: this.$store.getters.identificacion,
          };

          axios
            .post("https://raas.com.co/raasApi/security/auth/v1/getUrlDirectExternal", body, config)
            .then((response) => {
              this.$store.commit("switchLoading", false);
              window.open(response.data.urlRedirect, "_blank");
            });
        });
      }
    },
  },
};
</script>
