<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" lg="3" class="lateral-home-left">
        <router-link to="/home">
          <img class="img-logo-home" src="@/assets/images/Logo_ProgreSER_W.png" alt="Logo" />
        </router-link>
      </b-col>
      <b-col cols="12" lg="9" class="px-4 pb-5 mb-3">
        <Navbar />
        <router-view />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Navbar from "@/app/core/components/Navbar.vue";

export default {
  components: { Navbar },
};
</script>
